
import { Component, Prop, Vue } from "vue-property-decorator";
import { OrderStatus } from "@/models";
import { SettingsOrderStatuses } from "@/services/SOLO";

@Component
export default class OrderStatusIsVisible extends Vue {
  @Prop() orderStatus!: OrderStatus;

  $notify: any
    
  async onChange(value: boolean) {
    try {
      const response = await SettingsOrderStatuses.update(this.orderStatus.id, {
        "is-visible-pickup": value
      });
      // this.$notify({
      //   title: "DATA SAVED",
      //   verticalAlign: "bottom",
      //   horizontalAlign: "left",
      //   message: "Order Status has been updated!",
      //   type: "success",
      //   icon: "fas fa-check"
      // });
    } catch (error) {
      console.log(error);
      // this.$notify({
      //   title: "SYSTEM ERROR!",
      //   verticalAlign: "bottom",
      //   horizontalAlign: "left",
      //   message: "Something went wrong, please try again!",
      //   type: "danger",
      //   icon: "fas fa-bomb"
      // });
    }
  }
}
